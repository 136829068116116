.partner-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 0 0;
  @include media( '<=small' ) {
    grid-template-columns: 1fr;
  }
  @include media( '>=medium' ) {
    grid-template-columns: 1fr 1fr;
  }
  @include media( '>=large' ) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  li {
    display: flex;
    margin: 10px;
    padding: 10px;
    box-shadow: 0 0 0 1px #000;
    overflow: hidden;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    a {
      overflow: hidden;
      display: flex;
      margin: auto;
    }
  }
}

.no-overflow {
  overflow: hidden;
}

.partner-item-wrapper {
  opacity: 0;
  transition: all 0.2s ease-in;
  background-color: rgba(0, 0, 0, .8);
  margin: auto;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  &.visible {
    opacity: 1;
    z-index: 1000;
  }
  .partner-item {
    max-width: 1280px;
    margin: auto;
    position: relative;
    height: 100%;
    .close {
      position: absolute;
      right: 1em;
      top: 5%;
      z-index: 1000;

      a {
        margin-left: auto;
        padding-right: 15%;
        padding-top: 5%;
        display: flex;
        width: 50px;
        height: 50px;
        .inner {
          position: relative;
          display: inline-block;
          width: 25px;
          height: 25px;
        }
        .inner::before,
        .inner::after {
          content: ' ';
          width: $header-hamburger--size * 2;
          height: 1px;
          position: absolute;
          background: color-icon(hamburger);
          border-radius: $header-hamburger--radius;
          top: 50%;
          bottom: 50%;
          left: 0;
        }
        .inner::before {
          transform: rotate(45deg);
        }
        .inner::after {
          transform: rotate(-45deg);
        }
      }
    }

    .col-half {
      width: 50%;
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
    }
    .col-half.left {
      img {
        flex: 0 auto;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
      @include media( '<=small' ) {
        display: none;
      }
      .founded,
      .vertical {
        z-index: 1090;
        position: absolute;
        left: 50px;
        text-align: left;
        display: inline-block;
        right: 0;
        color: #fff;
        @include font-family(heading);
        @include font-weight(heading);
        & div {
          width: 50%;
          max-width: 300px;
          border-bottom: 2px solid white;
          font-weight: normal;
        }

        time {
          display: block;
          font-size: 30px;
          line-height: 50px;
        }
      }
      .founded {
        bottom: 200px;
      }
      .vertical {
        bottom: 100px
      }
    }
    .col-half.right {
      background: #fff;
      right: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 2em 3em 1em 3em;

      @include media( '<=small' ) {
        padding: 0 1em 0 1em;
      }

      @include media( '>small' ) {
        padding: 2em 3em 1em 3em;
      }

      @include media( 'height<small' ) {
        padding: 0 1.5em;
      }

      .progress-wrapper {
        width: 100%;
        border-top: 1px solid black;
        display: flex;
        align-self: flex-end;
        .progress {
          background: #000;
          height: 3px;
          transition: all 200ms ease-in;
        }
      }
      @include media( '<=small' ) {
        width: 100%;
      }

      .top {
        display: flex;
        min-height: 100px;
        height: 15%;
        @include media( 'height<small' ) {
          min-height: 10px;
        }
      }
      .center {
        height: 75%;
        display: flex;
        flex-direction: column;
        text-align: left;
        border-bottom: thin solid #000;
        h3 {
          margin: 0.75em 0 0.5em 0;
          @include media( 'height<small' ) {
            margin: 0.4em 0;
          }
        }
        .description {
          color: get-color(dark, 3);
          overflow: auto;
          font-size: 16px;
          margin-bottom: 0.1em;
        }
        .partner-url-outer {
          display: flex;
          align-items: flex-end;
          flex-grow: 1;
          .partner-url {
            display: flex;
            padding: 10px 0 30px 0;
            @include media( 'height<small' ) {
              padding: 5px 0 5px 0;
            }
            text-transform: uppercase;
          }
        }

      }
      .bottom {
        min-height: 100px;
        height: 15%;
        display: flex;
        @include media( 'height<small' ) {
          min-height: 60px;
        }
      }
    }
  }

  .thin-spaced-text {
    font-size: 12px;
    letter-spacing: 3.3px;

    &:hover .light-arrow,
    &:active .light-arrow {
      left: 0.85em;
    }
  }

  .light-arrow {
    display: flex;
    background: get-color(dark, 3);
    width: 60px;
    height: 1px;
    position: relative;
    margin-left: 1.5em;
    transition: all 0.2s ease-in;
    left: 0;
    bottom: -1.1em;

    &::before,
    &::after {
      display: block;
      transform: rotate(45deg);
      background: get-color(dark, 3);
      width: 12px;
      height: 1px;
      right: -0.2em;
      position: absolute;
      content: ' ';
    }
    &::before {
      transform: rotate(40deg);
      top: -4px;
    }
    &::after {
      transform: rotate(-40deg);
      top: 4px;
    }
  }

  .navigation  {
    text-transform: uppercase;
    display: flex;
    align-self: flex-end;
    margin-left: auto;
    margin-bottom: 1em;

    a {
      padding: 0 20px;
      min-width: 6em;
      text-align: left;

      &.prev {
        display: inline-block;
      }
      &.last {
        display: inline-block;
      }

      .light-arrow {
        bottom: 1.4em;
        margin-left: 5.5em;
      }
    }
  }
}

.timeline {
  padding-top: 3em;
  .vertical-timeline {
    text-align: left;
  }
  ul {
    margin: 1em 0;
    li {
      font-size: 20px;
    }
  }
}
