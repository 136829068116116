.banner {
  position: relative;
  @include media( '<=small' ) {
    //min-height: 40vh;
    min-height: calc(100vh - 80px);
  }
  @include media( '>small' ) {
    //min-height: 50vh;
    min-height: calc(100vh - 80px);
  }
  img {
    flex: 0 auto;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
    user-select: none;
  }
  .title {
    min-width: 100%;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -30%) !important;;
    @include media( '<=small' ) {
      transform: translate(-50%, -50%) !important;
    }
    &.is-revealed {
      transform: translate(-50%, -50%) !important;;
    }
  }
}

.darker-bg {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.list-wrapper {
  display: flex;
  text-align: left;
  padding: 50px 0;

  .list-left {
    width: 40%;
    display: inline-block;
    blockquote {
      margin-bottom: 50px;
    }
  }
  .list-right {
    width: 60%;
    display: inline-block;
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        padding-right: 30px;
        h6 {
          display: flex;
          text-transform: uppercase;
        }
      }
    }
  }
  @include media( '<large' ) {
    display: block;
    .list-left,
    .list-right {
      display: block;
      width: 100%;
      ul {
        display: block;
        li {
          width: 100%;
        }
      }
    }
  }
}
