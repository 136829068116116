.Privacy {
  h6 {
    margin: 0 0 .5em 0;
  }
}
.imprint-address {
  text-align: left;

  border-top: thin solid get-color(light, 1);

  address {
    padding: 0;
    border: 0;
    font-style: normal;
  }
}

.privacy-policy {
  text-align: left;
  border-top: thin solid get-color(light, 1);

  .privacy-inner {
    white-space: pre-wrap;
    word-break: break-word;
  }

  strong {
    color: get-color(light, 3);
    @include font-family(heading);
  }
  ul {
    margin-left: 1em;
    li {
      margin-bottom: 0.2em;
    }
  }
}
