.tweets {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: left;
  .tweet-item {
    margin: 1em;
    @include media( '<=small' ) {
      margin-right: 0;
      padding: 0.5em 0.2em 0.5em 0;
    }
    padding: 1em;
    display: flex;
    flex-direction: row-reverse;
    border-bottom: thin solid rgba(0, 0, 0, .1);
    &:first-of-type {
      border-top: thin solid rgba(0, 0, 0, .1);
    }
    .tweet-item-image-link {
      display: flex;
      height: fit-content;
      @include media( '<=small' ) {
        min-width: 110px;
      }
      min-width: 200px;
      width: 20%;

      img {
        width: 100%;
        margin: 0;
        display: flex;
      }
    }
    .tweet-item-section {
      width: 80%;
      margin-right: 20px;
      h6 {
        margin: 0.3em 0;
      }
      time {
        color: get-color(dark, 3);
        font-size: 14px;
      }
      p {
        color: get-color(dark, 3);
        font-size: 14px;
        margin-bottom: 0.5em;
      }
    }
  }

}
