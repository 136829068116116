.Careers {
  .Careers-content {
    position: relative;
    .careers-city {
      position: relative;
      img {
        left: 0;
        top: 0;
        user-select: none;
        z-index: -2;
        object-fit: cover;
        flex: 0 auto;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      color: get-color(dark, 1);
      padding: 4em 2em;
      .button-transparent {
        background-color: transparent;
        color: get-color(dark, 1);
        border-color: get-color(dark, 1);
        &:hover,
        &:active {
          background-color: rgba(255, 255, 255, .5);
          border: 0;
          color: get-color(light, 1);
        }
      }
    }
  }

  .jobs-list-wrapper {
    .buttons-wrapper {
      display: flex;
      padding-right: 20px;
      a {
        margin-left: auto;
      }
      @include media( '<large' ) {
        display: block;
        padding-left: 20px;
        a {
          width: 100%;
        }
      }
    }
  }
}

.expert-contact-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: left;
  color: get-color(dark, 1);
  .form-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include media( '<=medium' ) {
      flex-direction: column;
    }
  }
  .half {
    display: flex;
    flex-direction: column;
    width: 50%;
    @include media( '<=medium' ) {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
  .full-width {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
