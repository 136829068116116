.Faq {
  background: linear-gradient(152deg, #2e2f2f, #101111);
  text-align: left;
  color: get-color(dark, 1);

  .Faq-inner {
    padding: 0;
  }

  a {
    color: get-color(primary, 2);
  }

  .Faq-content {
    display: flex;
    @include media( '<=small' ) {
      display: block;
    }

    .half {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 50%;
      @include media( '<=small' ) {
        width: 100%;
      }
      &:first-of-type {
        margin-right: 2em;
        @include media( '<=small' ) {
          margin-right: 0;
        }
      }
    }
  }
}

.faq-item {

  .collapsible-head {
    display: flex;
    cursor: pointer;
    border-bottom: thin solid get-color(dark, 1);

    .collapsible-title {
      margin-right: 1em;
    }
    .MuiSvgIcon-root {
      margin-right: 0;
    }

  }
  .collapsible-body {
    opacity: 0;
    transition: all 0.2s ease-in;
    @include font-family(heading);

    &.visible {
      opacity: 1;
    }
  }
  .icon-arrow-down,
  .icon-arrow-up {
    color: get-color(dark, 1);
    display: flex;
    width: 10px;
    height: 10px;
    position: relative;
  }
  .icon-arrow-down {
    &::before, &::after {
      position: absolute;
      display: block;
      content: ' ';
      width: 1em;
      height: 1em;
      border-right: thin solid get-color(dark, 1);
    }
    &::before {
      transform: rotate(45deg);
      top: -1em;
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  .icon-arrow-up {
    content: '⌄'
  }
}
