.site-footer {
  background-color: get-color(dark, 4);
  color: get-color(dark, 1);

  .footer-nav {
    .list-reset li {
      display: flex;
      &::after {
        content: ' ';
        display: flex;
        height: 10px;
        margin-top: 5px;
        border-right: thin solid get-color(dark, 1);
      }
      &:last-of-type::after {
        border-right: none;
      }
      a {
        color: get-color(dark, 1);
        @include font-family(heading);
      }
    }
  }


  .site-footer-inner {
    border-top: thin solid get-color(dark, 1);
    border-bottom: thin solid get-color(dark, 1);
    margin: 6em 0;
  }

  .brand {
    h1 {
      @include media( '<=small' ) {
        transform: translateX(50%);
        left: -56px;
      }
    }
    h1 img:last-of-type {
      margin: 0;
      left: inherit;
    }
    .logo {
      display: none;
    }
    .brand {
      position: inherit;
    }

  }

  p {
    margin: 2em 0;
    text-align: left;
    line-height: 24px;
  }

}
