.contact-partner {
  padding-top: 3em;
  text-align: left;

  display: flex;
  flex-direction: row;
  address {
    border: 0;
    margin: 0;
    padding: 0;
  }
  h1 {
    margin: 0;
  }
  h6 {
    margin: 1em 0 .5em 0;
    &:nth-child(2) {
      margin-top: 0;
    }
  }
  .half {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  @include media( '<=medium' ) {
    flex-direction: column;

    .half {
      width: 100%;
    }
  }
  @include media( '>=large' ) {
    .half:first-of-type {
      width: 75%;
    }
    .half:last-of-type {
      width: 25%;
    }
  }

  .grey-text {
    color: get-color(dark, 3);
    font-style: normal;
  }
}
.timeline {
  svg {
    left: 0;
  }
}

.investor-introduction {
  position: relative;
  flex-direction: column;
  display: flex;
  min-height: 400px;
  text-align: left;
  .container {

    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-self: center;
    justify-content: center;

    h3, p, button {
      color: get-color(dark, 1);
    }

    button {
      background-color: transparent;
      max-width: 15em;
    }
  }
  //flex-grow: 1;
  //p {
  //  display: flex;
  //  position: absolute;
  //  top: 25%;
  //  z-index: 3;
  //}
  .image-bg {
    left: 0;
    top: 0;
    user-select: none;
    z-index: -2;
    object-fit: cover;
    flex: 0 auto;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .introduction-bg {
    user-select: none;
    position: absolute;
    background: linear-gradient(to right,#0d1623 0%,rgba(13,22,35,0.99) 1%,rgba(13,22,35,0.5) 55%,rgba(13,22,35,0.49) 56%,rgba(13,22,35,0) 100%);
    width: 80%;
    height: 100%;
    z-index: 2;
  }
  .text-content {
    z-index: 3;
  }
  //.image-bg {
  //  width: 100%;
  //  z-index: 1;
  //}
}

.about-kopax {
  .investors-list-wrapper {
    @include media( '<large' ) {
      display: block;
      .experts-list-left,
      .experts-list-right {
        display: block;
        width: 100%;
        ul {
          display: block;
          li {
            width: 100%;
          }
        }
      }
    }
    .buttons-wrapper {
      display: flex;
      padding-right: 20px;
      a {
        margin-left: auto;
      }
      @include media( '<large' ) {
        display: block;
        padding-left: 20px;
        a {
          width: 100%;
        }
      }
    }
  }
}

.investor-footer {
  color: get-color(dark, 1);
  text-align: left;

  .container {

    .text-notice {
      padding: 5em 0;
      max-width: 500px;
      @include media( '<=small' ) {
        max-width: inherit;
      }
      a {
        color: get-color(primary, 2);
      }
    }

  }
}

.investor-news {
  padding: 2em 0;
}

.investor-news-inner {
  text-align: left;
  color: #fff;
  display: flex;
  flex-direction: row;
  h3 {
    color: #fff;
    margin-top: 0;
  }
  .half {
    display: flex;
    flex-direction: column;
    align-self: center;
    flex-grow: 1;
    &.left {
      left: 70%;
      padding: 0 1.5em 0 0;
      border-right: 1px solid #fff;
    }
    &.right {
      left: 30%;
      padding: 0 0 0 1.5em;
    }
  }

  @include media( '<large' ) {
    display: block;
    .half {
      &.left,
      &.right {
        width: 100%;
        padding: 1em 0;
        border: 0;
      }
      &.right {
        padding-top: 4em;
      }
    }
  }

  form {
    .form-control {
      display: flex;
      flex-direction: row;
      position: relative;
      .invalid-message {
        position: absolute;
        bottom: -25px;
      }
      input {
        padding: 0 15px;
        border-radius: 5px 0 0 5px;
        @include media( '<medium' ) {
          width: 100%;
        }
        &:focus {
          box-shadow: none;
          &.invalid-value {
            box-shadow: none;
          }
        }
      }
      button {
        border: 0;
        border-radius: 0 5px 5px 0;
        min-width: 129px;
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
}

.profile-item {
  color: #fff;
  text-align: left;
  display: flex;
  .profile-item-inner {
    display: flex;
    align-items: center;
    .identity {
      margin-left: 1.5em;
    }
  }
  .avatar {
    border-radius: 100%;
    border: 2px solid #fff;
    width: 120px;
  }

  .identity {
    align-content: space-between;
    height: 100%;
    h5 {
      color: #fff;
      margin: 0;
    }
    .position {
      font-style: italic;
      margin: 1em 0 1.3em 0;
    }
    a.follow {
      @include font-family(heading);
      line-height: 1.5em;
      display: inline-block;
      color: #fff;
      text-transform: uppercase;
      border-bottom: 1px solid #aaa;
      &:hover,
      &:active {
        border-color: #fff;
      }
    }
  }

}
