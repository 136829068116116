legend {
  @include font-family(heading);
  @include font-weight(heading);
  font-size: 20px;
  padding: 24px 0;
}

.button {
  background-color: get-color(dark, 1);
  border: thin solid get-color(primary, 3);
  text-transform: uppercase;
  color: get-color(primary, 3);
  &:hover, &:hover:visited {
    background-color: get-color(primary, 1);
    border: thin solid get-color(primary, 1);
    color: get-color(dark, 1);
  }
  &:visited {
    background-color: get-color(dark, 1);
    border: thin solid get-color(primary, 1);
    color: get-color(primary, 1);
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
  p {
    margin: 12px 0;
  }

  &.hidden {
    position: absolute;
    top: -1000px;
    left: -1000px;
    max-height: 1px;
    max-width: 1px;
    opacity: 0;
    padding: 0;
  }

  &.active {
    border-color: get-color(dark, 2);
  }
  &.accept {
    border-color: get-color(alert, success);
  }
  &.reject {
    border-color: get-color(alert, warning);
  }
}


.form-control {
  @include font-family(heading);

  label {
    display: block;
  }

  textarea {
    min-width: 100%;
    min-height: 10em;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    @include font-family(heading);

    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 300px;
    outline: none;
    padding: 18.5px 14px 18.5px 14px;
    transition: box-shadow ease .25s,border-color ease .25s;
    color: #495057;
    &.invalid-value {
      border-color: #f44336;
    }
    &:focus {
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25);
      &.invalid-value {
        border-color: #f44336;
        box-shadow: 0 0 0 0.2rem rgba(222,0,0,0.25);
      }
    }
  }
  .invalid-message {
    font-style: italic;
    visibility: hidden;
    color: get-color(alert, error);
    &.visible {
      visibility: visible;
    }
  }
}
.form-error {
  text-transform: uppercase;
  margin: 1em 0;
  visibility: hidden;
  animation: all 0.2s ease-in;
  &.visible {
    visibility: visible;
  }
}
