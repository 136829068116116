.Jobs {
  ul.jobs-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
    li {
      width: 50%;
      padding: 0;
      margin: 0;
      &:nth-child(odd) {
        padding-right: 30px;
      }
      &:nth-child(even) {
        padding-left: 30px;
      }
      @include media( '<=medium' ) {
        width: 100%;
        &:nth-child(even) {
          padding-left: 0;
        }
      }
    }
  }
}
