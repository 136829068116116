table {
  margin-bottom: 0;
  border: thin solid white;
  &.table-select tbody tr {
    transition: all 150ms ease-in;
    &:hover {
      background-color: rgba(0, 0, 0, .3);
    }
    &:active,
    &:focus {
      background-color: rgba(0, 0, 0, .5);
    }
  }
  th {
    @include font-weight(heading);
    text-transform: uppercase;
    color: get-color(light, 3);
    line-height: 3.6em;
    &:first-child {
      padding-left: 1em;
    }
    &:last-child {
      padding-right: 1em;
    }
  }
  td {
    padding: 0;
    a {
      line-height: 2.5em;
      padding: 8px 16px;
    }
    &:first-child {
      a {
        padding-left: 1em;
      }
    }
    &:last-child {
      a {
        padding-right: 1em;
      }
    }
  }
  th,
  td {
    @include font-family(heading);
    a {
      display: block;
      width: 100%;
      height: 100%;
      &:not(.button) {
        color: get-color(light, 1);
      }
    }
  }
  &.table-inverse {
    &.table-select tbody tr {
      &:hover {
        background-color: rgba(255, 255, 255, .3);
      }
      &:active,
      &:focus {
        background-color: rgba(255, 255, 255, .5);
      }
    }
    th {
      color: get-color(dark, 3);
    }
    th,
    td {
      a {
        &:not(.button) {
          color: get-color(dark, 1);
        }
      }
    }
  }
}
