header.site-header.is-revealed {
  &.header--fixed {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: transparent;
    transition: all 0.3s ease-in-out !important;

    &.top {
      transition: none;
      transform: translateY(0);
    }
    &.not-top {
      position: fixed;
      transform: translateY(-100%);
    }
    &.slide-down.not-top {
      transform: translateY(0);
      background-color: color-bg(header);
      //box-shadow: 0 1px 3px get-color(dark, 3);
    }
    &.slide-down.top {
      position: fixed;
    }
    &.slide-up.not-top {
      transform: translateY(-100%);
      background-color: color-bg(header);
    }
    &.slide-up.top {
      transform: translateY(-100%);
      position: absolute;
    }
  }
}

.brand {
  .header-nav {
    z-index: 10000;
  }
  & h1 {
    position: relative;
    min-width: 170px;
    height: 36px;

    & img {
      position: absolute;
      display: block;
    }
    & img:first-of-type {
      width: 36px;
      height: 36px;
    }
    & img:last-of-type {
      width: 118px;
      height: 36px;
      left: 40px;
    }
  }
}

