.Position {
  .Position-content {
    display: flex;
    text-align: left;
    flex-direction: row;
    @include media( '<=medium' ) {
      flex-direction: column;
      .quote-wrapper {
        blockquote {
          margin-left: 0;
          margin-bottom: 30px;
        }
      }
    }
    .quote-wrapper {
      blockquote {
        margin-top: 0;
      }
    }
    .quote-wrapper,
    .description-wrapper {
      display: flex;
      width: 50%;
      @include media( '<=medium' ) {
        width: 100%;
      }
    }
  }

  .position-table-wrapper {
    background-image: linear-gradient(152deg, #2e2f2f, #101111);
    color: get-color(dark, 1);
    text-align: left;
  }

}

.PositionSheet {
  header {
    display: flex;
    margin-bottom: 1.5em;
    .title-wrapper {
      border-top: thin solid get-color(light, 3);
      padding-top: 1em;
      h1 {
        font-size: 38px;
        margin: 0;
        padding: 0 0 10px 0;
        @include media( '<=medium' ) {
          font-size: 28px;
        }
      }
      h6 {
        color: get-color(dark, 3);
        margin: 0;
      }
      .location {
        color: get-color(dark, 3);
        font-size: 16px;
      }
    }
    .title-wrapper,
    .logo-wrapper {
      display: flex;
      flex-direction: column;
      width: 50%;
      justify-content: flex-start;
    }
    .title-wrapper {
      @include media( '<=large' ) {
        width: 60%;
      }
    }
    .logo-wrapper {
      @include media( '<=large' ) {
        width: 40%;
      }
    }
    .logo-wrapper {
      svg {
        width: 50%;
        margin-left: auto;
        margin-bottom: auto;
        @include media( '<=medium' ) {
          width: 80%;
        }
      }
    }
  }
  .apply-wrapper {
    //margin-right: auto;
    //text-align: center;
    a {
      margin-top: 50px;
      padding-left: 5em;
      padding-right: 5em;
      font-size: 12px;
      letter-spacing: 3.3px;
      @include media( '<=small' ) {
        width: 100%;
      }
    }
  }
  ul {
    margin: 12px 0;
    li {
      margin: 0;
    }
  }
}
.PositionApply {
  h6 {
    margin-bottom: 0;
  }
  .location {
    @include font-family(heading);
    margin-left: -3px;
    svg {
      height: 15px;
      width: 15px;
    }
  }
  .description {
    margin: 10px 0;
    @include font-family(heading);
    color: get-color(light, 3);
  }

  .file-item {
    display: flex;
    button {
      display: flex;
      cursor: pointer;
      border: 0;
      background-color: transparent;
      &:focus {
        outline: none;
      }
    }
  }
}
