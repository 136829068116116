.cookie-consent {
  background-color: get-color(light, 1);
  color: get-color(dark, 1);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1001;

  .cookie-consent-inner {
    display: flex;
    margin: 2em auto 1em auto;
    @include media( '<medium' ) {
      flex-direction: column;

    }
  }

  .cookie-consent-button-wrapper {
    display: flex;
    margin-left: 1em;
    flex-direction: column;

    .button {
      display: flex;
      width: 100%;
      background-color: get-color(dark, 1);
      &:first-of-type {
        margin-right: 0;
        margin-bottom: 0.5em;
        border: thin solid get-color(dark, 3);
        background-color: transparent;

        color: get-color(dark, 2);;
        &:hover,
        &:active {
          background-color: rgba(0, 0, 0, .7);
          color: get-color(dark, 1);
          border: thin solid get-color(dark, 1);
        }
      }
      &:last-of-type {
        &:hover,
        &:active,{
          color: get-color(dark, 1);
          background-color: get-color(primary, 3);
        }
      }
    }
    @include media( '<medium' ) {
      margin-left: 0;
    }

    @include media( '>large' ) {
      margin-left: 5em;
      flex-direction: row;
      .button {
        min-width: 9em;
        &:first-of-type {
          margin-right: 1em;
        }
      }
    }
  }

  .further-information {
    @include font-family(heading);
    color: get-color(dark, 2);
    margin-left: 0.5em;
  }
}
