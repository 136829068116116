@if ( $font--provider == 'Google Fonts' ) {
    @import url('https://fonts.googleapis.com/css?family=#{$font--import}&display=swap');
}
@if ( $font--provider == 'Locale' ) {
    @font-face {
        font-family: '#{$font--import}';
        font-style: normal;
        font-weight: normal;
        src: local('#{$font--import}'), url('../../../fonts/#{$font--import}/#{$font--import} Regular.woff') format('woff');
    }
    @font-face {
        font-family: '#{$font--import} Italic';
        font-style: normal;
        font-weight: normal;
        src: local('#{$font--import} Italic'), url('../../../fonts/#{$font--import}/#{$font--import} Italic.woff') format('woff');
    }
    @font-face {
        font-family: '#{$font--import} Bold';
        font-style: normal;
        font-weight: normal;
        src: local('#{$font--import} Bold'), url('../../../fonts/#{$font--import}/#{$font--import} Bold.woff') format('woff');
    }

    @font-face {
        font-family: '#{$font--import-secondary}';
        font-style: normal;
        font-weight: normal;
        src: local('#{$font--import-secondary}'), url('../../../fonts/#{$font--import-secondary}/#{$font--import-secondary} Regular.woff') format('woff');
    }
    @font-face {
        font-family: '#{$font--import-secondary} Italic';
        font-style: normal;
        font-weight: normal;
        src: local('#{$font--import-secondary} Italic'), url('../../../fonts/#{$font--import-secondary}/#{$font--import-secondary} Italic.woff') format('woff');
    }
    @font-face {
        font-family: '#{$font--import-secondary} Bold';
        font-style: normal;
        font-weight: normal;
        src: local('#{$font--import-secondary} Bold'), url('../../../fonts/#{$font--import-secondary}/#{$font--import-secondary} Bold.woff') format('woff');
    }


}
